import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import Content from '../Content';
import FieldSwitcher from './FieldSwitcher';
import { media } from '../theme';
import Button from '../Button';
// import useSiteContext from '../SiteContext';

import { FormContextProvider } from './formUtils';
import { encode } from '../utils';

const Form = props => {
  // console.log(props);
  const { title, description, fields, submitText, successMessage } = props;
  const { handleSubmit, register, errors, control } = useForm();
  const [success, setSuccess] = useState(false);

  // const { viewport } = useSiteContext();
  const formRef = useRef();
  console.log(fields);

  const hasUploads = fields.some(field => field._type === 'fileUpload');

  const onSubmit = data => {
    // console.log({ data, encoded: encode(data) });
    let formData = new FormData(formRef.current);
    formData.append('form-name', title);

    console.log(data);

    // let newFormData = new FormData();
    // Object.entries(data).forEach(field => {
    //   const [key, value] = field;
    //   if (typeof value === 'string') {
    //     newFormData.append(key, value);
    //   } else
    // })

    fetch('/', {
      method: 'POST',
      headers: {
        'Content-Type': hasUploads
          ? 'multipart/form-data'
          : 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        setSuccess(true);
        window.scroll({
          top: formRef.current.scrollTop,
          behavior: 'smooth',
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
  return (
    <FormContextProvider value={{ register, errors, control }}>
      <FormWrapper
        className="form"
        onSubmit={handleSubmit(onSubmit)}
        data-netlify="true"
        netlify-honeypot="honeypotField"
        name={title}
        ref={formRef}
      >
        <h2 className="form__title">{title}</h2>
        <Content className="form__description">{description}</Content>
        {success ? (
          <p style={{ color: 'green' }}>{successMessage}</p>
        ) : (
          <fieldset className="form__fields">
            <div className="fieldset-flex-fix">
              {fields.map(field => (
                <FieldSwitcher key={field._key} field={field} />
              ))}
              <label className="honeypot">
                Don't fill this out if you're human:{' '}
                <input name="honeypotField" />
              </label>
              <Button
                el="input"
                type="submit"
                className="form__submit"
                value={submitText || 'Submit'}
              />
            </div>
          </fieldset>
        )}
      </FormWrapper>
    </FormContextProvider>
  );
};

const FormWrapper = styled.form`
  fieldset {
    border: 0;
    padding: 0;
  }
  @supports (display: grid) {
    .fieldset-flex-fix {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 1rem;
      grid-gap: 1rem;
    }
  }
  .form {
    &__submit {
      justify-self: start;
      -webkit-appearance: none;
      border-radius: 0px;
      grid-column: span 2;
      margin-right: 1rem;
    }
  }
  .field-address {
    grid-column: span 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
  }
  .complex-field-label {
    /* font-family: synthese, sans-serif; */
    /* font-family: ${({ theme }) => theme.font.heading}; */
    font-size: 1.8rem;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 2px;
    line-height: 2.15;
    margin: 0 0 0.5rem;
    /* font-size: 1.4rem; */
    text-transform: uppercase;
    display: block;
    grid-column: span 2;
    width: 50%;
    ::after {
      content: '';
      width: 100%;
      height: 1px;
      background: ${({ theme }) => theme.orange};
      display: block;
    }
  }
  ${media.break`

  `}
  .honeypot {
    display: none;
  }
`;
export default Form;
