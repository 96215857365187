import styled from 'styled-components';
import classNames from 'classnames';

// import { grid } from '../theme';

const FieldWrapper = styled.span.attrs(({ halfWidth }) => ({
  className: classNames('field-wrapper', { halfWidth }),
}))`
  width: 100%;
  height: 100%;
  position: relative;
  /* display: inline; */
  @supports (display: grid) {
    grid-column: ${({ halfWidth }) => (halfWidth ? 'span 1' : 'span 2')};
  }
`;

const Label = styled.label.attrs(props => {
  // console.log(props);
  const { halfWidth } = props;
  return {
    className: classNames('field-label', { halfWidth }),
  };
})`
  /* height: 100%; */
  width: 100%;
  position: relative;
  transition: 0.15s;
  display: block;
  border: ${({ isFocused, theme }) =>
    `${isFocused ? '3px' : '2px'} solid ${theme.orange}`};
  &:not(.field-file) {
    margin: ${({ isFocused }) =>
      isFocused ? '0px 0px 5px 1px' : '1px 1px 6px'};
  }
  @supports (display: grid) {
    grid-column: ${({ halfWidth }) => (halfWidth ? 'span 1' : 'span 2')};
  }
  .label-text {
    position: absolute;
    top: 50%;
    left: 1rem;
    transition: 0.15s;
    &:not(.label-text--file) {
      transform: ${({ isFocused }) =>
        isFocused ? 'translateY(-140%)' : 'translateY(-50%)'};
      font-size: ${({ isFocused }) => isFocused && '1.2rem'};
    }
    pointer-events: none;
    &.message-span {
      top: 0;
      transform: translateY(0);
    }
  }

  &.field-textarea {
    .label-text {
      top: 0;
      transform: translateY(0);
    }
  }

  &.field-file {
    /* border: none; */
    .label-text {
      /* position: static; */
    }
  }

  input,
  textarea,
  select {
    background: transparent;
    border: 0;
    width: 100%;
    padding: 1rem 1rem;
    margin-bottom: 0;
    margin-top: 1rem;
    color: ${props => props.theme.dark};
    /* display: block; */
    &:focus {
      outline: none;
    }
  }

  &.checkboxes,
  &.radiobuttons {
    .label-text {
      position: relative;
    }
  }
`;
export { FieldWrapper };
export default Label;
