import React from 'react';
import Helmet from 'react-helmet';

const Meta = () => {
  return (
    <Helmet>
      <link rel="stylesheet" href="https://use.typekit.net/iqh1stq.css" />
    </Helmet>
  );
};

export default Meta;
