// import { useState, useEffect, useLayoutEffect, useRef } from 'react';
import imageUrlBuilder from '@sanity/image-url';
import sanityClient from '@sanity/client';

const client = new sanityClient({
  projectId: '0ny3ua3p',
  dataset: 'production',
  token:
    'sk4kYs1xJROOijXcGeRVyrzI8OQotlYr002oQnOL5DiQrPnakVAmBvE5CfICKOXh5DbBPrYpal8v0NCdF05lKLlD6uuiIJ7VdtlkUibJudjCHsJq3vofE5OhPpWEkMJH3t1H93RKcly4cBur8Hfazk7rVX6D96NbzemucWIg8YNd5kxIU1kt',
});
const builder = imageUrlBuilder(client);
function imgUrl(source) {
  return builder.image(source);
}

function getSize() {
  const isClient = typeof window === 'object';
  return {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
  };
}

// function useViewport() {
//   const isClient = typeof window === 'object';

//   const [windowSize, setWindowSize] = useState(getSize);

//   useEffect(() => {
//     if (!isClient) {
//       return false;
//     }

//     function handleResize() {
//       setWindowSize(getSize());
//     }

//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []); // Empty array ensures that effect is only run on mount and unmount

//   return windowSize;
// }

function calcCrop(image, containerWidth) {
  const { crop } = image.crop
    ? image
    : { crop: { top: 0, bottom: 0, left: 0, right: 0 } };

  // console.log({ image, hotspot });
  const { width, height } = image.asset.metadata.dimensions;
  const heightRatio = 1 - crop.bottom - crop.top;
  const widthRatio = 1 - crop.left - crop.right;
  const croppedFullHeight = height * heightRatio;
  const croppedFullWidth = width * widthRatio;
  const croppedAspect = croppedFullWidth / croppedFullHeight;
  const imageHeight = containerWidth / croppedAspect;
  const objectPosition = `${Math.round(crop.left * 100)}% ${Math.round(
    crop.top * 100
  )}%`;
  return { height: imageHeight, objectPosition };
}

const roundToNearest = (num, multiple) => {
  return Math.ceil(num / multiple) * multiple;
};

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

export {
  getSize,
  // useViewport,
  calcCrop,
  // useElementSize,
  client,
  imgUrl,
  roundToNearest,
  encode,
};
