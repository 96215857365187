import React, {
  createContext,
  useContext,
  useState,
  useLayoutEffect,
} from 'react';

import { getSize } from './utils';

const SiteContext = createContext();

const SiteContextProvider = ({ home, children }) => {
  const [ready, setReady] = useState(false);
  const [viewport, setViewport] = useState({ width: 0, height: 0 });
  useLayoutEffect(() => {
    setViewport(getSize());
    setReady(true);
  }, []);
  return (
    <SiteContext.Provider value={{ ready, viewport, home }}>
      {children}
    </SiteContext.Provider>
  );
};

const useSiteContext = () => useContext(SiteContext);

export { SiteContextProvider, SiteContext };
export default useSiteContext;
