import React, { useState, useLayoutEffect, useEffect, useRef } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { useSpring, useTransition, animated } from 'react-spring';

// import { media } from './theme';
import { imgUrl, roundToNearest } from './utils';
import { useHasEntered } from './hooks';

const Image = ({ className, containerWidth, wrapperHeight, image, onLoad }) => {
  const base64 = image.asset.metadata.lqip;
  const [src, setSrc] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [switched, setSwitched] = useState(false);

  useLayoutEffect(() => {
    if (containerWidth) {
      // TODO: round based on height for wide images on mobile. it has to stretch a whole lot to cover
      const width = roundToNearest(containerWidth, 100);
      const url = imgUrl(image).width(width).url();
      setSrc(url);
    }
  }, [containerWidth, image]);

  const ref = useRef();
  const hasEntered = useHasEntered(ref);

  useEffect(() => {
    if (loaded && hasEntered) {
      setSwitched(true);
    }
  }, [loaded, hasEntered]);

  const imageSpring = useSpring({
    opacity: switched ? 1 : 0,
  });

  const imagePlaceholderTransition = useTransition(switched, null, {
    from: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <ContentImage
      className={classNames('content-image', className)}
      width={containerWidth}
      height={wrapperHeight}
      ref={ref}
    >
      <animated.img
        className="content-image__image"
        src={src}
        alt={image.alt}
        onLoad={() => {
          setLoaded(true);
          onLoad();
        }}
        style={imageSpring}
      />
      {imagePlaceholderTransition.map(
        ({ item, key, props }) =>
          !item && (
            <animated.img
              key={key}
              className="content-image__placeholder"
              src={base64}
              alt={image.alt}
              style={props}
            />
          )
      )}
    </ContentImage>
  );
};

const ContentImage = styled.div`
  /* width: 100%;
  height: 100%; */
  position: relative;
  overflow: hidden;
  .content-image {
    &__image {
      /* position: absolute; */
    }
    &__placeholder {
      object-fit: cover;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
`;

export default Image;
