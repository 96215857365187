import React from 'react';
import Helmet from 'react-helmet';

const FooterMeta = () => {
  return (
    <Helmet>
      <script src="https://js.adsrvr.org/up_loader.1.1.0.js" type="text/javascript"></script>
      <script type="text/javascript">
        {`ttd_dom_ready( function() {
                if (typeof TTDUniversalPixelApi === 'function') {
                    var universalPixelApi = new TTDUniversalPixelApi();
                    universalPixelApi.init("bvhnnnn", ["boa1ow5"], "https://insight.adsrvr.org/track/up");
                }
            });`}
      </script>
    </Helmet>
  );
};

export default FooterMeta;
