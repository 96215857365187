import React, { useRef } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { animated, useSpring } from 'react-spring';

import Image from './Image';
import Button from './Button';

import useSiteContext from './SiteContext';
import { media } from './theme';
import bgPattern from './bgPattern';
import { useElementSize, useHasEntered } from './hooks';

const NewContentBlock = ({
  className,
  heading,
  copy,
  image,
  slug,
  link,
  reverse = false,
  backgroundPattern = false,
  brickRoadBg = false,
}) => {
  const {
    file: {
      id,
      childImageSharp: {
        sizes: { src: brickRoad },
      },
    },
  } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "ALL-ELEMENTS-07.png" }) {
        id
        childImageSharp {
          sizes {
            src
          }
        }
      }
    }
  `);

  const [contentWrapperRef, contentWrapperSize, trigger] = useElementSize();

  const { viewport, home } = useSiteContext();

  const imageColumnWidth =
    viewport.width > 768
      ? home
        ? viewport.width * 0.3
        : viewport.width * 0.5
      : viewport.width;

  const blockWrapperRef = useRef();

  const onScreen = useHasEntered(blockWrapperRef);

  // console.log(`${heading} on screen: `, onScreen);

  const spring = useSpring(
    onScreen
      ? {
          opacity: 1,
          transform: 'translateY(0px)',
        }
      : {
          opacity: 0,
          transform: 'translateY(50px)',
        }
  );

  // console.log(heading, contentWrapperSize);

  return (
    <BlockWrapper
      className="content-block"
      reverse={reverse}
      id={slug && slug.current}
      bg={brickRoadBg ? brickRoad : false}
      containerWidth={imageColumnWidth}
      viewport={viewport}
      ref={blockWrapperRef}
      style={spring}
    >
      {image && (
        <Image
          containerWidth={imageColumnWidth}
          wrapperHeight={contentWrapperSize.height}
          image={image}
          className="content-block__image"
          onLoad={trigger}
        />
      )}
      <ContentWrapper
        className="content-block__content-wrapper"
        reverse={reverse ? reverse : undefined}
        ref={contentWrapperRef}
        wrapperSize={contentWrapperSize}
        leftAngle={63}
        rightAngle={79}
        backgroundPattern={backgroundPattern}
      >
        <div className="content-block__content">
          <h2 className="content-block__heading">{heading}</h2>
          <p
            className="content-block__body"
            dangerouslySetInnerHTML={{ __html: copy }}
          />
          {link && (
            <Button className="content-block__link" href={link.url}>
              {link.label}
            </Button>
          )}
        </div>
      </ContentWrapper>
    </BlockWrapper>
  );
};

function absVal(num) {
  if (num < 0) {
    return num * -1;
  }
  return num;
}

const BlockWrapper = styled(animated.section)`
  margin-top: 5rem;
  /* overflow: hidden; */
  /* background-image: url(${({ bg }) => bg});
  background-position: 110% 90%;
  background-repeat: no-repeat;
  background-size: 55%; */
  position: relative;
  &::before {
    /* content: url(${({ bg }) => bg}); */
    content: ${({ bg }) => (bg ? `url(${bg})` : bg)};
    position: absolute;
    top: 75%;
    right: -220px;
    width: 65%;
    opacity: .5;
    height: 213px;
    overflow: hidden;
  }
  ${media.break`
    width: 100% !important;
    display: flex;
    .content-block {
      &__image {
        flex: 0 1 auto;
        order: ${({ reverse }) => (reverse ? 2 : 1)};
        
      }
      &__content-wrapper {
        flex: 1 1 auto;

        margin-left: ${({ reverse }) => (reverse ? 0 : 10)}px;
        margin-right: ${({ reverse }) => (reverse ? 10 : 0)}px;
        order: ${({ reverse }) => (reverse ? 1 : 2)};
        
      }
    }
  `}
`;

const ContentWrapper = styled.div`
  padding: 5rem 1rem;
  max-width: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  ${media.break`
    width: 300px;
  `}
  ${({ backgroundPattern }) => backgroundPattern && bgPattern}
  > * {
    position: relative;
  }
  .content-block {
    &__content {
      position: relative;
      z-index: 1;
      ${media.break`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 100%;
        width: 65%;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        align-items: ${({ reverse }) => (reverse ? 'flex-end' : 'flex-start')};
        text-align: ${({ reverse }) => (reverse ? 'right' : 'left')};
      `}
    }
    &__heading {
      margin: 0;
      color: ${({ theme, reverse }) => (reverse ? theme.red : theme.orange)};
    }
    &__body {
      margin: 0 0 4rem;
    }
  }
`;

export { ContentWrapper };
export default NewContentBlock;
