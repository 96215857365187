import { createGlobalStyle } from 'styled-components';
import { media } from './theme';
const GlobalStyle = createGlobalStyle`

  html {
    box-sizing: border-box;
    font-size: 10px;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    scroll-behavior: smooth;

  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    font-family: ${({ theme }) => theme.font.family};
    background: ${({ theme }) => theme.blue};
    color: ${({ theme }) => theme.black};
    font-size: 1.6rem;
  }

  h2 {
    color: ${({ theme }) => theme.orange};
    font-size: 3rem;
    text-transform: uppercase;
    ${media.break`
      font-size: 6rem;
    `}
    font-family: ${({ theme }) => theme.font.heading};
  }

  h3 {
    font-family: ${({ theme }) => theme.font.heading};
  }
  img {
    max-width: 100%;
    height: auto;
  }
`;

export default GlobalStyle;
