import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import classNames from 'classnames';
import styled from 'styled-components';
import { animated } from 'react-spring';
import { Link } from 'gatsby';

import Merch from './Merch';
import ContentBlock from './ContentBlock';
import Form from './Form';

const serializers = {
  types: {
    merchBlock: props => {
      const { node } = props;
      // console.log(props);
      return <Merch {...node} />;
    },
    specialBlock: ({ node }) => {
      return <ContentBlock {...node} />;
    },
    form: ({ node }) => {
      return <Form {...node} />;
    },
    altImage: ({ node }) => {
      return (
        <p className="content-image-wrapper">
          {node.asset && (
            <img
              className="content-image"
              src={node.asset.url}
              alt={node.alt || null}
              style={{ width: 'auto', display: 'initial' }}
            />
          )}
        </p>
      );
    },
  },
  marks: {
    file: ({
      children,
      mark: {
        asset: { url },
      },
    }) => {
      return (
        <a class="file-link" href={url} target="_blank">
          {children}
        </a>
      );
    },
    link: ({ children, mark }) => {
      console.log(mark);
      const { externalUrl, url } = mark;
      if (externalUrl) {
        return (
          <a href={externalUrl} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        );
      }
      return <Link to={`/${url.slug.current}`}>{children}</Link>;
    },
  },
};
const Content = React.forwardRef(({ className, children, style }, ref) => {
  return (
    <Container
      style={style}
      className={classNames('block-content', className)}
      ref={ref}
    >
      <BlockContent
        blocks={children}
        projectId="0ny3ua3p"
        dataset="production"
        serializers={serializers}
        className="block-content-wrapper"
      />
    </Container>
  );
});

const Container = styled(animated.div)`
  .page-wrapper & {
    > *:not(.block-content-wrapper),
    .block-content-wrapper > * {
      width: ${({ theme }) => theme.sizes.content}px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export default Content;
