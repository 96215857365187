import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import classNames from 'classnames';

import { SiteContextProvider } from './SiteContext';
import Header from './Header';
import Tickets from './Tickets';
import Connect from './Connect';
import Footer from './Footer';
import Meta from './Meta';
import FooterMeta from './FooterMeta';

import theme from './theme';
import GlobalStyle from './GlobalStyle';

const Wrapper = ({ home = false, children, className }) => {
  return (
    <ThemeProvider theme={theme}>
      <SiteContextProvider home={home}>
        <SiteWrapper className={classNames('site-wrapper', className)}>
          <Meta />
          <Header />
          {children}
          <Tickets />
          <Connect />
          <Footer />
          <GlobalStyle />
          <FooterMeta />
        </SiteWrapper>
      </SiteContextProvider>
    </ThemeProvider>
  );
};

const SiteWrapper = styled.div``;

export default Wrapper;
