import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiFillTwitterSquare,
} from 'react-icons/ai';

import NavItem from './NavItem';

import { media } from './theme';
import bgPattern from './bgPattern';
import { useElementSize } from './hooks';

const Connect = () => {
  const {
    sanitySiteSettings: {
      contact: {
        social: { facebook, instagram, twitter },
      },
    },
    sanityConnect,
  } = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        contact {
          email
          phone
          social {
            facebook
            instagram
            twitter
          }
        }
      }
      sanityConnect {
        heading
        text
        slug {
          current
        }
        menu {
          _key
          anchor
          buttonStyles
          externalUrl
          label
          link {
            ... on SanityHome {
              _type
            }
            ... on SanityInfoPage {
              slug {
                current
              }
            }
            ... on SanityLineupPage {
              slug {
                current
              }
            }
            ... on SanityPage {
              slug {
                current
              }
            }
          }
          subMenu {
            _key
            anchor
            buttonStyles
            externalUrl
            label
            link {
              ... on SanityHome {
                id
                _type
              }
              ... on SanityInfoPage {
                slug {
                  current
                }
              }
              ... on SanityLineupPage {
                slug {
                  current
                }
              }
              ... on SanityPage {
                slug {
                  current
                }
              }
            }
          }
        }
      }
    }
  `);
  const { heading, menu, slug, text } = sanityConnect;
  const [ref, wrapperSize] = useElementSize();

  return (
    <StyledConnect className="connect" id={slug.current}>
      <div className="connect__left">
        {heading && <h2 className="connect__heading">{heading}</h2>}
        {text && <p className="connect__text">{text}</p>}
        {menu.length > 0 && (
          <nav className="connect__nav">
            {menu.map(item => (
              <NavItem className="connect__nav" key={item._key} {...item} />
            ))}
          </nav>
        )}
      </div>
      <ConnectRight
        className="connect__right"
        ref={ref}
        wrapperSize={wrapperSize}
        leftAngle={63}
        rightAngle={79}
      >
        <ul className="connect__social">
          {facebook && (
            <li className="connect__social-item">
              <a
                className="connect__social-link"
                href={facebook}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiOutlineFacebook className="connect__social-icon facebook" />
              </a>
            </li>
          )}
          {instagram && (
            <li className="connect__social-item">
              <a
                className="connect__social-link"
                href={instagram}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiOutlineInstagram className="connect__social-icon instagram" />
              </a>
            </li>
          )}
          {twitter && (
            <li className="connect__social-item">
              <a
                className="connect__social-link"
                href={twitter}
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiFillTwitterSquare className="connect__social-icon twitter" />
              </a>
            </li>
          )}
        </ul>
      </ConnectRight>
    </StyledConnect>
  );
};

const ConnectRight = styled.div`
  ${bgPattern}
  ${media.break`
    flex: 0 0 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
  .connect {
    &__social {
      height: 200px;
      list-style: none;
      display: flex;
      padding: 0;
      align-items: center;
      justify-content: center;
      margin: 0;
      width: 100%;
      top: 0;
      position: relative;
      z-index: 1;
      ${media.break`
        height: 100%;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
      `}
    }
    &__social-item {
      flex-shrink: 1;
      flex-basis: 100px;
    }
    &__social-link {
      margin-left: 1rem;
      margin-right: 1rem;
      display: block;
      width: 100%;
      height: 100%;
    }
    &__social-icon {
      width: 100%;
      height: 100%;
      max-width: 100px;
      max-height: 100px;
      color: ${({ theme }) => theme.black};
    }
  }
`;

const StyledConnect = styled.section`
  ${media.break`
    display: flex;
  `}
  .connect {
    &__left {
      flex: 0 0 50%;
      padding: 3rem 2rem;
      ${media.break`
        padding: 3rem 6rem;
      `}
    }
    &__right {
      flex: 0 0 50%;
    }
    &__heading {
      color: ${({ theme }) => theme.orange};
      text-transform: uppercase;
      font-size: 5rem;
      margin: 0;
    }
    &__text {
      text-transform: uppercase;
      font-size: 1.8rem;
      margin-top: 0.5rem;
    }
    &__nav {
      display: flex;
      flex-direction: column;
    }
    &__nav__item {
      font-weight: ${({ theme }) => theme.font.bold};
      line-height: 2;
      display: block;
      color: ${({ theme }) => theme.black};
      text-decoration: none;
      text-transform: none;
      font-size: 1.6rem;
    }
  }
`;

export { bgPattern };

export default Connect;
