import React from 'react';
import styled from 'styled-components';

import Button from './Button';
// import { bgPattern } from './Connect';
import { ContentWrapper } from './ContentBlock';
import theme, { media } from './theme';
import { useElementSize } from './hooks';
import { calcCrop } from './utils';
import CroppedImage from './CroppedImage';
import useSiteContext from './SiteContext';

const Merch = props => {
  // console.log(props);
  const { heading, image, link, description, slug } = props;
  const { viewport } = useSiteContext();

  const { height, objectPosition } = calcCrop(image, viewport.width * 0.5);
  const [contentWrapperRef, contentWrapperSize] = useElementSize();

  return (
    <StyledMerch className="merch-block" id={slug && slug.current}>
      <div className="merch-block__image-wrapper">
        {image && (
          <CroppedImage
            className="merch-block__image"
            src={image.asset.url}
            alt={image.alt}
            object-fit="cover"
            objectPosition={objectPosition}
            height={height}
            image={image}
          />
        )}
      </div>
      <ContentWrapper
        className="merch-block__content-wrapper"
        height={viewport.width > theme.sizes.break ? 500 : 300}
        ref={contentWrapperRef}
        wrapperSize={contentWrapperSize}
        leftAngle={63}
        rightAngle={79}
      >
        <div className="merch-block__content">
          <h2 className="merch-block__heading">{heading}</h2>
          {description && <p className="merch-block__body">{description}</p>}
          {link && (
            <Button className="merch-block__link" href={link.url}>
              {link.label}
            </Button>
          )}
        </div>
      </ContentWrapper>
    </StyledMerch>
  );
};

const StyledMerch = styled.section`
  display: flex;
  flex-direction: column;
  margin: 5rem 0;
  .page .page-wrapper .block-content-wrapper & {
    width: 100%;
  }
  ${media.break`
    flex-direction: row; 
    @supports (display: grid) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      grid-gap: 1rem;
      align-items: center;
      grid-template-areas: ${({ reverse }) =>
        reverse
          ? `
        "content image";
      `
          : `
        "image content";
      `};
    }
    .merch-block {
      &__image-wrapper {
        height: 100%;
      }
    }
  `}
  .merch-block {
    &__image-wrapper {
      position: relative;
    }
    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &__content {
      position: relative;
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      height: 100%;
      width: 75%;
      ${media.break`
        justify-content: center;
        width: 65%;
        margin-left: auto;
        margin-right: auto;
      `}
    }
    &__heading {
      margin: 0 0 1rem;
    }
    &__body {
      margin-bottom: 4rem;
    }
    ${media.break`
      &__image-wrapper {
        flex: 0 0 50%;
        order: ${({ reverse }) => (reverse ? 2 : 1)};
        grid-area: image;
      }
      &__content-wrapper {
        flex: 0 0 50%;
        order: ${({ reverse }) => (reverse ? 1 : 2)};
        grid-area: content;
      }
    `}
  }
`;

export default Merch;
