import { useState, useEffect, useLayoutEffect, useRef } from 'react';

function useOnScreen(ref, rootMargin = '0px') {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);
  const refCopy = ref;
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      }
    );
    if (refCopy.current) {
      observer.observe(refCopy.current);
    }
    return () => {
      observer.unobserve(refCopy.current);
    };
  }, [rootMargin, refCopy]); // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting;
}

function useHasEntered(ref, rootMargin = '0px') {
  // State and setter for storing whether element is visible
  const [hasEntered, setHasEntered] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        if (entry.isIntersecting) {
          setHasEntered(entry.isIntersecting);
          observer.unobserve(ref.current);
        }
      },
      {
        // rootMargin,
        threshold: 0.5,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.unobserve(ref.current);
    };
  }, [ref, rootMargin]); // Empty array ensures that effect is only run on mount and unmount

  return hasEntered;
}

function getSize() {
  const isClient = typeof window === 'object';
  return {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
  };
}

function useViewport() {
  const isClient = typeof window === 'object';

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

const useElementSize = () => {
  const [elementSize, setElementSize] = useState({
    width: 0,
    height: 0,
  });

  const elementRef = useRef();

  useLayoutEffect(() => {
    if (elementRef.current) {
      trigger();
    }
  }, [elementRef.current]);

  const trigger = () => {
    const size = elementRef.current.getBoundingClientRect();
    setElementSize(size);
  };

  return [elementRef, elementSize, trigger];
};

function useForm(initial = {}) {
  console.log('initial: ', initial);
  // create a state object for our inputs
  const [inputs, setInputs] = useState(initial);
  console.log('inputs: ', inputs);
  const initialValues = Object.values(initial);

  // useEffect(() => {
  //   // This function runs when the things we are watching change
  //   setInputs(initial);
  // }, [initialValues]);

  function handleChange(e) {
    let { value, name, type } = e.target;
    if (type === 'number') {
      value = parseInt(value);
    }
    if (type === 'file') {
      [value] = e.target.files;
    }
    setInputs({
      // copy the existing state
      ...inputs,
      [name]: value,
    });
  }

  function resetForm() {
    setInputs(initial);
  }

  function clearForm() {
    const blankState = Object.fromEntries(
      Object.entries(inputs).map(([key, value]) => [key, ''])
    );
    setInputs(blankState);
  }

  // return the things we want to surface from this custom hook
  return {
    inputs,
    handleChange,
    resetForm,
    clearForm,
  };
}

export { useOnScreen, useViewport, useElementSize, useHasEntered, useForm };
