import React from 'react';
import TimePicker from 'react-time-picker';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';

import Label, { FieldWrapper } from './Label';
import { useFocusState, useFormContext } from './formUtils';
import ErrorMessage from './ErrorMessage';

const TimeField = ({ name, fieldOptions }) => {
  const required =
    fieldOptions && fieldOptions.required ? fieldOptions.required : false;
  const halfWidth =
    fieldOptions && fieldOptions.halfWidth ? fieldOptions.halfWidth : false;
  const description =
    fieldOptions && fieldOptions.description ? fieldOptions.description : false;
  const adminLabel =
    fieldOptions && fieldOptions.adminLabel ? fieldOptions.adminLabel : false;
  const fieldName = adminLabel ? adminLabel : name;

  const { focused, handleFocus, handleBlur } = useFocusState();

  const {
    // register,
    errors,
    control,
  } = useFormContext();
  const error = errors[fieldName];

  return (
    <FieldWrapper halfWidth={halfWidth} className="time-field-wrapper">
      <TimeFieldLabel
        className="field-text field-time"
        isFocused={focused}
        htmlFor={fieldName}
        halfWidth={halfWidth}
      >
        <span className="label-text">
          {name}
          {required && '*'}
        </span>
        <Controller
          as={TimePicker}
          control={control}
          className="text-input"
          name={fieldName}
          onFocus={handleFocus}
          onBlur={handleBlur}
          autocomplete="false"
        />
      </TimeFieldLabel>
      {description && <p className="field-description">{description}</p>}
      {error && <ErrorMessage className="error--text-area" {...error} />}
    </FieldWrapper>
  );
};

const TimeFieldLabel = styled(Label)`
  .react-time-picker {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 1rem;
    &__wrapper {
      border: 0;
    }
    &__inputGroup {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      transition: 0.25s;
      opacity: ${({ isFocused }) => (isFocused ? 1 : 0)};
      select,
      input {
        margin: 1rem 0 0;
        flex-grow: 0;
        flex-shrink: 1;
      }
      select {
        width: 50px;
      }
      &__input {
        flex: 1 0 auto;
        padding: 0 0.8rem;
        :invalid {
          background: transparent;
        }
      }
    }
    &__button {
      :enabled:hover .react-time-picker__button__icon,
      :enabled:focus .react-time-picker__button__icon {
        stroke: ${({ theme }) => theme.orange};
      }
    }
  }
  select {
    color: ${({ theme }) => theme.dark};
  }
  input {
    margin-top: 0;
    letter-spacing: 0.9px;
  }

  .react-clock {
    &__face {
      border-color: ${({ theme }) => theme.orange};
    }
    &__minute-mark {
      &__body {
        background: ${({ theme }) => theme.orange};
      }
    }
    &__minute-hand {
      &__body {
        background: ${({ theme }) => theme.orange};
      }
    }
  }
`;

export default TimeField;
