import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { media } from './theme';
import NavItem from './NavItem';

const Footer = () => {
  const {
    sanityFooterSettings: { footerLogos, sponsor, footerNav },
  } = useStaticQuery(graphql`
    {
      sanityFooterSettings {
        footerLogos {
          _key
          label
          link
          logo {
            alt
            asset {
              fixed(width: 100) {
                ...GatsbySanityImageFixed
              }
            }
          }
        }
        sponsor {
          heading
          logo {
            alt
            asset {
              fixed(width: 250) {
                ...GatsbySanityImageFixed
              }
            }
          }
          link
        }
        footerNav {
          _key
          anchor
          buttonStyles
          externalUrl
          label
          link {
            ... on SanityHome {
              _type
            }
            ... on SanityInfoPage {
              slug {
                current
              }
            }
            ... on SanityLineupPage {
              slug {
                current
              }
            }
            ... on SanityPage {
              slug {
                current
              }
            }
          }
          subMenu {
            _key
            anchor
            buttonStyles
            externalUrl
            label
            link {
              ... on SanityHome {
                id
                _type
              }
              ... on SanityInfoPage {
                slug {
                  current
                }
              }
              ... on SanityLineupPage {
                slug {
                  current
                }
              }
              ... on SanityPage {
                slug {
                  current
                }
              }
            }
          }
        }
      }
    }
  `);
  return (
    <StyledFooter className="footer">
      {footerNav.length && (
        <FooterNav className="footer__nav">
          <ul className="footer-menu">
            {footerNav.map(item => (
              <NavItem className="footer-menu" key={item._key} {...item} />
            ))}
          </ul>
        </FooterNav>
      )}
      <FooterLogos className="footer__logos">
        {footerLogos.map(({ _key, label, link, logo }) => (
          <li className="footer__logo footer-logo" key={_key}>
            {link ? (
              <a className="footer-logo__link" href={link} aria-label={label}>
                {logo.asset && (
                  <Img
                    className="footer-logo__logo"
                    fixed={logo.asset.fixed}
                    alt={logo.alt}
                  />
                )}
              </a>
            ) : (
              <>
                {logo.asset && (
                  <Img
                    className="footer-logo__logo"
                    fixed={logo.asset.fixed}
                    alt={logo.alt}
                  />
                )}
              </>
            )}
          </li>
        ))}
      </FooterLogos>
      <FooterSponsor className="footer__sponsor">
        {sponsor.link ? (
          <a
            className="footer__sponsor-link"
            href={sponsor.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {sponsor.heading && (
              <span className="footer__sponsor-heading">{sponsor.heading}</span>
            )}
            {sponsor.logo?.asset?.fixed && (
              <Img
                className="footer__sponsor-image"
                fixed={sponsor.logo.asset.fixed}
                alt={sponsor.logo.alt}
              />
            )}
          </a>
        ) : (
          <>
            {sponsor.heading && (
              <span className="footer__sponsor-heading">{sponsor.heading}</span>
            )}
            {sponsor.logo?.asset?.fixed && (
              <Img
                className="footer__sponsor-image"
                fixed={sponsor.logo.asset.fixed}
                alt={sponsor.logo.alt}
              />
            )}
          </>
        )}
      </FooterSponsor>
    </StyledFooter>
  );
};

const FooterSponsor = styled.div`
  text-align: center;
  ${media.break`
    padding-right: 2rem;
  `}
  a {
    color: inherit;
    text-decoration: none;
    display: block;
  }
  span {
    display: block;
    text-align: center;
    text-transform: uppercase;
    font-weight: ${({ theme }) => theme.font.bold};
  }
`;
const FooterLogos = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .footer-logo {
    flex: 0 1 100px;
    margin: 0 1rem;
  }
`;

const FooterNav = styled.nav`
  padding: 2rem;
  .footer-menu {
    margin: 0;
    padding: 0;
    list-style: none;
    ${media.break`
      display: flex;
    `}
    &__item {
      font-weight: ${({ theme }) => theme.font.bold};
      /* padding: 2rem; */
      text-transform: uppercase;
      font-size: 2rem;
      color: ${({ theme }) => theme.black};
      margin: 0 1rem 1rem;
    }
    .sub-menu__item {
      color: ${({ theme }) => theme.black};
      display: block;
    }
  }
  .sub-menu {
    margin: 0;
    padding: 0;
    list-style: none;
    &__item {
      text-transform: none;
      font-size: 1.6rem;
      line-height: 2;
    }
  }
`;
const StyledFooter = styled.footer`
  padding: 1rem;
  ${media.break`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 3rem; */
  `}
`;

export default Footer;
