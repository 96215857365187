import { css } from 'styled-components';

const bgPattern = css`
  position: relative;
  background: ${({ theme }) => theme.deco.orange};
  ::before,
  ::after {
    content: '';
    position: absolute;
    width: 0;
    height: 100%;
    top: 0;
    z-index: 0;
    border-style: solid;
  }
  ::before {
    left: 0;

    background: ${({ theme }) => theme.deco.yellow};
    border-color: ${({ theme }) => theme.deco.yellow};
    border-right-color: ${({ theme }) => theme.deco.orange};
    border-left-width: ${({ wrapperSize }) => wrapperSize.width * 0.15}px;
    border-bottom-width: 0;
    border-right-width: ${({ wrapperSize, leftAngle = 63 }) =>
      wrapperSize.height / Math.tan(leftAngle * (Math.PI / 180))}px;
    border-top-width: ${({ wrapperSize }) => wrapperSize.height}px;
    ${({ reverse, theme, wrapperSize, leftAngle = 63 }) =>
      reverse &&
      `
      border-color: ${theme.deco.pink};
      border-right-color: ${theme.deco.orange};
      border-left-width: ${wrapperSize.width * 0.15}px;
      border-right-width: ${
        wrapperSize.height / Math.tan(leftAngle * (Math.PI / 180))
      }px;
      border-top-width: ${wrapperSize.height}px;
      `}/* 
    border-top-color: red;
    border-right-color: blue;
    border-bottom-color: green;
    border-left-color: orange; */
  }
  ::after {
    right: 0;

    background: ${({ theme }) => theme.deco.pink};

    border-color: ${({ theme }) => theme.deco.pink};
    border-left-color: ${({ theme }) => theme.deco.orange};
    border-right-width: ${({ wrapperSize }) => wrapperSize.width * 0.15}px;
    border-left-width: ${({ wrapperSize, rightAngle = 79 }) =>
      wrapperSize.height / Math.tan(rightAngle * (Math.PI / 180))}px;
    border-top-width: ${({ wrapperSize }) => wrapperSize.height}px;
    ${({ reverse, theme, wrapperSize, rightAngle = 79 }) =>
      reverse &&
      `
      background: ${theme.deco.yellow};
      border-color: ${theme.deco.yellow};
      border-left-color: ${theme.deco.orange};
      border-right-width: ${wrapperSize.width * 0.15}px;
      border-left-width: ${
        wrapperSize.height / Math.tan(rightAngle * (Math.PI / 180))
      }px;
      border-top-width: ${wrapperSize.height}px;
    `}
  }
`;

export default bgPattern;
