import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Button from './Button';
import Content from './Content';
import TicketClipPath from './TicketClipPath';

import { media } from './theme';

const Tickets = () => {
  const {
    sanityTickets: { ticketOptions, heading, _rawText },
  } = useStaticQuery(graphql`
    {
      sanityTickets {
        heading
        _rawText(resolveReferences: { maxDepth: 10 })
        ticketOptions {
          _key
          _rawDescription(resolveReferences: { maxDepth: 10 })
          buyLink
          buyText
          price
          title
          image {
            alt
            asset {
              fluid(maxWidth: 300) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  `);
  return (
    <StyledTickets className="tickets" id="tickets">
      <h2 className="tickets__heading">{heading || 'Tickets'}</h2>
      <TicketClipPath />
      <TicketsOptions className="tickets-options">
        {ticketOptions.map(
          ({
            _key,
            _rawDescription,
            buyLink,
            buyText,
            price,
            title,
            image,
          }) => {
            return (
              <li className="option" key={_key}>
                <Img
                  className="option__image"
                  fluid={image.asset.fluid}
                  alt={image.alt}
                  style={{ clipPath: `url(#clipPath)` }}
                />
                <h3 className="option__name">{title}</h3>
                {price && <span className="option__price">{price}</span>}
                <Content className="option__description">
                  {_rawDescription}
                </Content>
                <Button
                  className="option__button"
                  href={buyLink}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {buyText || 'Buy'}
                </Button>
              </li>
            );
          }
        )}
      </TicketsOptions>
      {_rawText && (
        <TextUnderTickets className="text__tickets">
          {_rawText}
        </TextUnderTickets>
      )}
    </StyledTickets>
  );
};

const TextUnderTickets = styled(Content)`
  width: ${({ theme }) => theme.sizes.content};
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-family: ${({ theme }) => theme.font.heading};
  text-transform: uppercase;
`;
const TicketsOptions = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 75px;
  ${media.break`
    display: flex;
    width: 1200px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    align-items: flex-start;
    align-items: stretch;
    @supports (display: grid) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  `}
  .option {
    background: ${({ theme }) => theme.blue};
    padding: 2rem 2rem 0;
    margin-bottom: 12rem;
    height: 100%;
    ${media.break`
      margin-right: 2rem;
      margin-left: 2rem;
      margin-bottom: 5rem; 
    `}
    &__image {
      width: 250px;
      height: 250px;
      margin-left: auto;
      margin-right: auto;
      margin-top: -75px;
      img {
        clip-path: ellipse(50% 50% at 50% 50%);
      }
    }
    &__name {
      margin: 0;
      font-family: ${({ theme }) => theme.font.heading};
      text-transform: uppercase;
    }
    &__price {
      font-weight: ${({ theme }) => theme.font.bold};
      font-family: ${({ theme }) => theme.font.heading};
    }
    &__description {
      margin-bottom: 1rem;
      font-family: ${({ theme }) => theme.font.headingRegular};
    }
    &__button {
      text-transform: uppercase;
      font-weight: ${({ theme }) => theme.font.bold};
      display: inline-block;
      transform: translateY(50%);
    }
  }
`;
const StyledTickets = styled.section`
  background: white;
  padding: 3rem;
  margin-top: 2rem;
  text-align: center;
  .tickets {
    &__heading {
      text-align: center;
      text-transform: uppercase;
      font-size: 4rem;
      color: ${({ theme }) => theme.black};
    }
  }
`;

export default Tickets;
