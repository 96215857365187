import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

const Button = ({
  children,
  to,
  className,
  style,
  href,
  target,
  rel,
  el,
  type,
  value,
}) => {
  if (to) {
    return (
      <StyledLink
        style={style}
        className={classNames('button', className)}
        to={to}
      >
        {children}
      </StyledLink>
    );
  }
  if (el === 'input') {
    return (
      <StyledInput
        type={type}
        value={value}
        className={classNames('button', className)}
      />
    );
  }
  // if (href) {
  return (
    <StyledA
      style={style}
      className={classNames('button', className)}
      href={href}
      target={target}
      rel={rel}
    >
      {children}
    </StyledA>
  );
  // }
};

const ButtonStyle = css`
  background: ${({ theme }) => theme.orange};
  color: ${({ theme }) => theme.white};
  padding: 1rem 3rem;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.font.bold};
`;
const StyledA = styled.a`
  ${ButtonStyle}
  text-decoration: none;
`;
const StyledLink = styled(Link)`
  ${ButtonStyle}
`;
const StyledInput = styled.input`
  ${ButtonStyle}
  border: 0;
`;
export { ButtonStyle };
export default Button;
