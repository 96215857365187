import React from 'react';
import styled from 'styled-components';

import { media } from './theme';

const NavToggler = ({ navOpen, toggleNav }) => {
  return (
    <Toggler
      className="nav-toggler"
      open={navOpen}
      onClick={() => toggleNav(!navOpen)}
      size={50}
    >
      <span />
      <span />
      <span />
    </Toggler>
  );
};

const Toggler = styled.button`
  display: block;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border: 0;
  background: transparent;
  position: relative;
  outline: none;
  z-index: 1;
  ${media.break`
    display: none;
  `}
  span {
    display: block;
    position: absolute;
    background: ${({ theme }) => theme.red};
    top: 50%;
    height: 3px;
    width: 75%;
    left: 50%;
    transition: 0.15s;
    :nth-child(1) {
      transform: ${({ size, open }) =>
        `translateY(${
          open ? size * 0.5 : size * 0.3
        }px) translateX(-50%) rotate(${open ? '45deg' : '0deg'})`};
    }
    :nth-child(2) {
      transform: ${({ size }) =>
        `translateY(${size * 0.5}px) translateX(-50%)`};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    :nth-child(3) {
      transform: ${({ size, open }) =>
        `translateY(${
          open ? size * 0.5 : size * 0.7
        }px) translateX(-50%) rotate(${open ? '-45deg' : '0deg'})`};
    }
  }
`;

export default NavToggler;
