import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import classNames from 'classnames';

import { ButtonStyle } from './Button';

const NavItem = props => {
  // console.log(props);
  const {
    className,
    label,
    link,
    buttonStyles,
    anchor,
    externalUrl,
    subMenu,
  } = props;
  if (link && anchor) {
    return (
      <MenuLink
        className={classNames(
          'menu-item',
          `${className}__item`,
          'menu-item--internal-external'
        )}
        to={`/${link.slug.current}#${anchor}`}
        buttonStyles={buttonStyles}
      >
        {label}
      </MenuLink>
    );
  }
  if (link) {
    return (
      <MenuLink
        className={classNames(
          'menu-item',
          `${className}__item`,
          'menu-item--internal'
        )}
        to={`/${link.slug.current}`}
        buttonStyles={buttonStyles}
      >
        {label}
      </MenuLink>
    );
  }
  if (anchor) {
    return (
      <A
        className={classNames(
          'menu-item',
          `${className}__item`,
          'menu-item--anchor'
        )}
        href={`#${anchor}`}
        buttonStyles={buttonStyles}
      >
        {label}
      </A>
    );
  }
  if (externalUrl) {
    return (
      <A
        className={classNames(
          'menu-item',
          `${className}__item`,
          'menu-item--external'
        )}
        href={`#${anchor}`}
        buttonStyles={buttonStyles}
        target="_blank"
        rel="nofollow"
      >
        {label}
      </A>
    );
  }
  if (subMenu) {
    return (
      <MenuBlank
        className={classNames(
          'menu-item',
          `${className}__item`,
          'menu-item--sub-menu'
        )}
        buttonStyles={buttonStyles}
      >
        {label}
        <ul className="sub-menu">
          {subMenu.map(item => (
            <NavItem className="sub-menu" key={item._key} {...item} />
          ))}
        </ul>
      </MenuBlank>
    );
  }
  return (
    <MenuBlank
      className={classNames('menu-item', `${className}__item`)}
      buttonStyles={buttonStyles}
    >
      {label}
    </MenuBlank>
  );
};

const MenuItemStyles = css`
  text-decoration: none;
  font-weight: ${({ theme }) => theme.font.extrabold};

  font-size: 2rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.red};
  .subnav & {
    color: white;
  }
  ${({ buttonStyles }) => buttonStyles && ButtonStyle}
`;
const MenuBlank = styled.span`
  ${MenuItemStyles}
  display: block;
`;
const MenuLink = styled(Link)`
  ${MenuItemStyles}
`;
const A = styled.a`
  ${MenuItemStyles}
`;

export default NavItem;
