import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import NavItem from './NavItem';

import { media } from './theme';

const Nav = ({ open }) => {
  const {
    sanitySiteSettings: { mainMenu },
  } = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        mainMenu {
          label
          buttonStyles
          anchor
          externalUrl
          _key
          link {
            ... on SanityHome {
              _type
            }
            ... on SanityLineupPage {
              id
              slug {
                current
              }
            }
            ... on SanityInfoPage {
              id
              slug {
                current
              }
            }
            ... on SanityPage {
              id
              slug {
                current
              }
            }
          }
        }
      }
    }
  `);

  // console.log(mainMenu);
  return (
    <MainNav open={open} className="header-nav">
      {mainMenu.map(item => (
        <NavItem className="main-nav" key={item._key} {...item} />
      ))}
    </MainNav>
  );
};

const MainNav = styled.nav`
  position: absolute;
  top: 140px;
  left: 0;
  width: 100%;
  max-width: 1000px;
  background: ${({ theme }) => theme.blue};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 120px);
  padding: 3rem;
  justify-content: space-around;
  z-index: 0;
  transition: 0.25s;
  transform: ${({ open }) => (open ? 'translateY(0)' : 'translateY(-150%)')};
  ${media.break`
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    position: static;
    transform: none;
    height: 100%;
  `}
`;

export default Nav;
