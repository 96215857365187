import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import Nav from './Nav';
import NavToggler from './NavToggler';
import Content from './Content';

import { media } from './theme';

const Header = () => {
  const {
    file: {
      childImageSharp: { fixed: logo },
    },
    sanitySiteSettings: { _rawHeaderText },
  } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "rbr-logo.png" }) {
        id
        childImageSharp {
          fixed(width: 140) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sanitySiteSettings {
        _rawHeaderText(resolveReferences: { maxDepth: 10 })
      }
    }
  `);

  const [navOpen, toggleNav] = useState(false);

  return (
    <StyledHeader className="header">
      <Link className="header__logo-link" to="/">
        <Img className="header__logo" fixed={logo} alt="Red Brick Roads" />
      </Link>
      <Content className="header__text">{_rawHeaderText}</Content>
      <NavToggler navOpen={navOpen} toggleNav={toggleNav} />
      <Nav open={navOpen} />
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${({ theme }) => theme.sizes.header}px;
  position: relative;
  z-index: 2;
  padding: 0 2rem;

  ${media.break`
    padding-left: 3rem;
    padding-right: 0;
  `}

  .header {
    &__logo-link {
      ${media.break`
        margin-right: 5rem;
      `}
      position: relative;
      z-index: 1;
    }
    &__text {
      display: none;
      ${media.break`
        display: block;
      `}
      color: ${({ theme }) => theme.purple2};
      line-height: 1;
      margin: 0 2rem;
      width: 400px;
      p {
        margin: 0;
      }
      h3 {
        margin: 0;
      }
    }
  }
`;

export default Header;
